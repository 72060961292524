@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url(../assets/fonts/OpenSans-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url(../assets/fonts/OpenSans-LightItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  src: url(../assets/fonts/OpenSans-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: normal;
  src: url(../assets/fonts/OpenSans-Italic.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url(../assets/fonts/OpenSans-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url(../assets/fonts/OpenSans-SemiBoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  src: url(../assets/fonts/OpenSans-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: bold;
  src: url(../assets/fonts/OpenSans-BoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url(../assets/fonts/OpenSans-ExtraBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: url(../assets/fonts/OpenSans-ExtraBoldItalic.ttf) format('truetype');
}
